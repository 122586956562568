<template>
  <div>
    <va-card class="mb-4">
      <va-card-title class="display-3 ml-3">{{
        $t("menu.users")
      }}</va-card-title>
    </va-card>

    <va-card class="mb-4">
      <va-card-actions align="right">
        <va-button icon="fa-icon fa fa-plus" @click="showAddUser = true">{{
          $t("user_view.add_user")
        }}</va-button>
      </va-card-actions>

      <va-card-content>
        <div class="row">
          <div class="flex xs12 sm5 md5 lg3 xl2">
            <va-select
              v-model.number="searchBySelected"
              :label="$t('user_view.search_by')"
              text-by="label"
              value-by="id"
              track-by="id"
              :options="searchBy"
            />
          </div>

          <div class="flex xs12 sm7 md7 lg5 xl4">
            <va-input
              v-model="term"
              :placeholder="
                $t('user_view.search_by') +
                ' ' +
                searchBy[searchBySelected].label
              "
              clearable
            >
              <template #prependInner>
                <va-icon name="fa fa-search" />
              </template>
            </va-input>
          </div>

          <div class="flex xs12 sm6 md6 lg4 xl3">
            <va-popover
              :message="`${$t('user_view.show_all_popover')}`"
              placement="top"
            >
              <va-checkbox
                v-model="showAll"
                :label="$t('user_view.show_all')"
              />
            </va-popover>
          </div>

          <div class="flex xs12 sm6 md6 lg4 xl2 offset--lg8 offset--xl1">
            <va-select
              v-model.number="perPage"
              :label="$t('tables_common.per_page')"
              :options="perPageOptions"
            />
          </div>
        </div>

        <va-data-table
          :items="filteredData"
          :columns="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :no-data-html="$t('tables_common.no_data')"
          :hoverable="true"
          :loading="loading"
          :striped="true"
        >
          <template #header(id)></template>

          <template #cell(enabled)="{ source: enabled }">
            <va-badge
              v-if="enabled"
              :text="$t('tables_common.active')"
              color="success"
            ></va-badge>
            <va-badge
              v-if="!enabled"
              :text="$t('tables_common.inactive')"
              color="danger"
            ></va-badge>
          </template>

          <template #cell(adminListed)="{ source: adminListed }">
            <va-icon v-if="adminListed" class="fa fa-check" color="success" />
            <va-icon v-if="!adminListed" class="fa fa-close" color="danger" />
          </template>

          <template #cell(caListed)="{ source: caListed }">
            <va-icon v-if="caListed" class="fa fa-check" color="success" />
            <va-icon v-if="!caListed" class="fa fa-close" color="danger" />
          </template>

          <template #cell(walletListed)="{ source: walletListed }">
            <va-icon v-if="walletListed" class="fa fa-check" color="success" />
            <va-icon v-if="!walletListed" class="fa fa-close" color="danger" />
          </template>

          <template #cell(matching)="{ source: matching }">
            <va-icon v-if="matching" class="fa fa-check" color="success" />
            <va-icon v-if="!matching" class="fa fa-close" color="danger" />
          </template>

          <template #cell(enabled2FA)="{ source: enabled2FA }">
            <div v-if="enabled2FA.enabled">
              <va-badge
                :text="$t('tables_common.active')"
                color="success"
              ></va-badge>
              <va-popover
                :message="`${$t('user_view.deactivate_2FA')}`"
                placement="top"
              >
                <va-button
                  flat
                  normal
                  color="danger"
                  icon="fa fa-power-off"
                  @click="disable2FAModal(enabled2FA.id)"
                />
              </va-popover>
            </div>
            <va-badge
              v-if="!enabled2FA.enabled"
              :text="$t('tables_common.inactive')"
              color="danger"
            ></va-badge>
          </template>

          <template #cell(id)="{ source: id }">
            <va-popover
              v-if="!id.startsWith('Email:')"
              :message="`${$t('tables_common.edit')}`"
              placement="top"
            >
              <va-button
                flat
                color="info"
                icon="fa fa-pencil"
                @click="editUserModal(id)"
              />
            </va-popover>

            <va-popover
              :message="`${$t('tables_common.delete')}`"
              placement="top"
            >
              <va-button
                flat
                color="danger"
                icon="fa fa-trash"
                @click="removeUser(id)"
              />
            </va-popover>
          </template>
        </va-data-table>

        <div class="flex-center mt-3">
          <va-pagination
            v-if="pages > 1"
            v-model="currentPage"
            :pages="pages"
          />
        </div>
      </va-card-content>
    </va-card>

    <va-modal v-model="showAddUser" size="medium" :hideDefaultActions="true">
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("user_view.add_user") }}
        </p>
      </template>

      <slot>
        <form>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="addForm.email"
              type="email"
              :label="$t('user_view.email')"
              :error="!!errors.addUserErrors.emailErrors.length"
              :error-messages="errors.addUserErrors.emailErrors"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="addForm.fullname"
              :label="$t('user_view.user_name')"
              :error="!!errors.addUserErrors.fullnameErrors.length"
              :error-messages="errors.addUserErrors.fullnameErrors"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="addForm.password"
              :label="$t('auth.password')"
              :messages="$t('user_view.without_password')"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-select
              class="flex xs12 md12"
              v-model="addForm.organizationId"
              :label="$t('user_view.org_id')"
              searchable
              :options="organizations"
              :no-options-text="$t('user_view.no_orgs')"
              :error="!!errors.addUserErrors.organizationIdErrors.length"
              :error-messages="errors.addUserErrors.organizationIdErrors"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-select
              class="flex xs12 md12"
              v-model="addForm.appsAccessList"
              :label="$t('user_view.apps_access')"
              searchable
              multiple
              clearable
              text-by="name"
              track-by="name"
              :options="apps"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-select
              class="flex xs12 md12"
              v-model="addForm.accessListNames"
              :label="$t('user_view.access')"
              searchable
              multiple
              clearable
              :options="accessNames"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-checkbox
              v-model="addForm.enabled"
              :label="$t('common_forms.active')"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-checkbox
              v-model="addForm.sendEmail"
              :label="$t('user_view.send_email')"
            />
          </div>
        </form>
      </slot>

      <template #footer>
        <va-button color="danger" @click="cancelAdd" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button @click="addUser" icon="fa-icon fa fa-plus">{{
          $t("common_forms.create")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal v-model="showEditUser" size="medium" :hideDefaultActions="true">
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("user_view.edit_user") }}
        </p>
      </template>

      <slot>
        <form>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="editedUser.fullname"
              :label="$t('user_view.user_name')"
              :error="!!errors.editUserErrors.fullnameErrors.length"
              :error-messages="errors.editUserErrors.fullnameErrors"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="editedUser.password"
              :label="$t('auth.password')"
              :placeholder="$t('user_view.no_change_password')"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-select
              class="flex xs12 md12"
              v-model="editedUser.organizationId"
              :label="$t('user_view.org_id')"
              searchable
              :options="organizations"
              :no-options-text="$t('user_view.no_orgs')"
              :error="!!errors.editUserErrors.organizationIdErrors.length"
              :error-messages="errors.editUserErrors.organizationIdErrors"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-select
              class="flex xs12 md12"
              v-model="editedUser.appsAccessList"
              :label="$t('user_view.apps_access')"
              searchable
              multiple
              clearable
              text-by="name"
              track-by="name"
              :options="apps"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-select
              class="flex xs12 md12"
              v-model="editedUser.accessListNames"
              :label="$t('user_view.access')"
              searchable
              multiple
              clearable
              :options="accessNames"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-checkbox
              v-model="editedUser.enabled"
              :label="$t('common_forms.active')"
            />
          </div>
        </form>
      </slot>

      <template #footer>
        <va-button color="danger" @click="cancelEdit" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button @click="editUser" icon="fa fa-pencil">{{
          $t("common_forms.edit")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="showConfirmDelete"
      size="medium"
      :hideDefaultActions="true"
      :message="
        $t('user_view.delete_user_confirm') + ' \'' + editedUser.email + '\'?'
      "
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("user_view.delete_user") }}
        </p>
      </template>
      <template #footer>
        <va-button color="info" @click="cancelDelete" class="mr-3">
          {{ $t("common_forms.cancel") }}
        </va-button>
        <va-button color="danger" @click="confirmDelete" icon="fa fa-trash">{{
          $t("common_forms.delete")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="showDisable2FA"
      size="medium"
      :hideDefaultActions="true"
      :message="
        $t('user_view.deactivate_2FA_confirm') +
        ' \'' +
        editedUser.email +
        '\'?'
      "
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("user_view.deactivate_2FA") }}
        </p>
      </template>
      <template #footer>
        <va-button color="info" @click="cancelDisable2FA" class="mr-3">
          {{ $t("common_forms.cancel") }}
        </va-button>
        <va-button color="danger" @click="disable2FA" icon="fa fa-power-off">{{
          $t("me_view.deactivate")
        }}</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  data() {
    return {
      term: null,
      perPage: 10,
      perPageOptions: [10, 20, 50, 100],
      currentPage: 1,
      loading: true,
      tableData: [],
      showAll: false,
      organizations: [],
      apps: [],
      accesses: [],
      accessNames: [],
      showAddUser: false,
      showConfirmDelete: false,
      showEditUser: false,
      showDisable2FA: false,
      allAppsAccess: {
        id: "all",
        clientId: "all",
        name: "All apps",
      },
      adminAccess: {
        id: "admin",
        clientId: "admin",
        name: "Admin",
      },
      addForm: {
        fullname: "",
        email: "",
        organizationId: "",
        password: "",
        appsAccessList: [],
        accessListNames: [],
        sendEmail: true,
        enabled: true,
      },
      errors: {
        addUserErrors: {
          emailErrors: [],
          fullnameErrors: [],
          organizationIdErrors: [],
        },
        editUserErrors: {
          fullnameErrors: [],
          organizationIdErrors: [],
        },
      },
      editedUser: {
        fullname: "",
        enabled: true,
        id: "",
        email: "",
        organizationId: "",
        password: "",
        accessListNames: [],
        appsAccessList: [],
      },
      searchBySelected: 2,
    };
  },

  created() {
    this.getUsers();
    this.getOrganizations();
    this.apps.push(this.allAppsAccess);
    this.apps.push(this.adminAccess);
    this.addForm.appsAccessList.push(this.allAppsAccess);
    this.getApps();
    this.getAccs();
    this.loading = false;
  },

  computed: {
    fields() {
      if (this.showAll) {
        return [
          {
            key: "enabled",
            headerTitle: this.$t("user_view.active"),
            label: this.$t("user_view.active"),
            sortable: true,
          },
          {
            key: "email",
            headerTitle: this.$t("user_view.email"),
            label: this.$t("user_view.email"),
            sortable: true,
          },
          {
            key: "organizationId",
            headerTitle: this.$t("user_view.org_id"),
            label: this.$t("user_view.org_id"),
            sortable: true,
          },
          {
            key: "fullname",
            headerTitle: this.$t("user_view.user_name"),
            label: this.$t("user_view.user_name"),
            sortable: true,
          },
          {
            key: "appsAccessListNames",
            headerTitle: this.$t("user_view.apps_access"),
            label: this.$t("user_view.apps_access"),
          },
          {
            key: "accessListNames",
            headerTitle: this.$t("user_view.access"),
            label: this.$t("user_view.access"),
          },
          {
            key: "enabled2FA",
            headerTitle: this.$t("user_view.2FA"),
            label: this.$t("user_view.2FA"),
          },
          {
            key: "adminListed",
            headerTitle: "Admin",
            label: "Admin",
          },
          {
            key: "caListed",
            headerTitle: "CA",
            label: "CA",
          },
          {
            key: "walletListed",
            headerTitle: "Wallet",
            label: "Wallet",
          },
          {
            key: "matching",
            headerTitle: this.$t("user_view.matching"),
            label: this.$t("user_view.matching"),
          },
          {
            key: "id",
            headerTitle: "",
            label: "",
            align: "right",
          },
        ];
      } else {
        return [
          {
            key: "enabled",
            headerTitle: this.$t("user_view.active"),
            label: this.$t("user_view.active"),
            sortable: true,
          },
          {
            key: "email",
            headerTitle: this.$t("user_view.email"),
            label: this.$t("user_view.email"),
            sortable: true,
          },
          {
            key: "organizationId",
            headerTitle: this.$t("user_view.org_id"),
            label: this.$t("user_view.org_id"),
            sortable: true,
          },
          {
            key: "fullname",
            headerTitle: this.$t("user_view.user_name"),
            label: this.$t("user_view.user_name"),
            sortable: true,
          },
          {
            key: "appsAccessListNames",
            headerTitle: this.$t("user_view.apps_access"),
            label: this.$t("user_view.apps_access"),
          },
          {
            key: "accessListNames",
            headerTitle: this.$t("user_view.access"),
            label: this.$t("user_view.access"),
          },
          {
            key: "enabled2FA",
            headerTitle: this.$t("user_view.2FA"),
            label: this.$t("user_view.2FA"),
          },
          {
            key: "id",
            headerTitle: "",
            label: "",
            align: "right",
          },
        ];
      }
    },

    searchBy() {
      return [
        {
          id: 0,
          label: this.$t("user_view.email"),
          search: "email",
        },
        {
          id: 1,
          label: this.$t("user_view.org_id"),
          search: "organizationId",
        },
        {
          id: 2,
          label: this.$t("user_view.user_name"),
          search: "fullname",
        },
      ];
    },

    filteredData() {
      let tmpData;
      if (!this.showAll) {
        tmpData = this.tableData.filter((item) => {
          return item.valid;
        });
      } else {
        tmpData = this.tableData;
      }
      if (!this.term || this.term.length < 1) {
        return tmpData;
      }

      if (this.searchBy[this.searchBySelected]) {
        switch (this.searchBy[this.searchBySelected].search) {
          case "fullname":
            return tmpData.filter((item) => {
              return (
                !!item.fullname &&
                item.fullname.toLowerCase().startsWith(this.term.toLowerCase())
              );
            });
          case "email":
            return tmpData.filter((item) => {
              return item.email
                .toLowerCase()
                .startsWith(this.term.toLowerCase());
            });
          case "organizationId":
            return tmpData.filter((item) => {
              return item.organizationId
                .toLowerCase()
                .startsWith(this.term.toLowerCase());
            });
          default:
            return tmpData;
        }
      } else {
        return tmpData;
      }
    },

    pages() {
      const pages =
        this.perPage && this.perPage !== 0
          ? Math.ceil(this.filteredData.length / this.perPage)
          : this.filteredData.length;
      if (pages < this.currentPage) {
        this.currentPage = pages === 0 ? 1 : pages;
      }
      return pages;
    },

    addFormReady() {
      return (
        !this.errors.addUserErrors.fullnameErrors.length &&
        !this.errors.addUserErrors.emailErrors.length &&
        !this.errors.addUserErrors.organizationIdErrors.length
      );
    },
  },

  methods: {
    search: debounce(function (term) {
      this.term = term;
    }, 400),

    addUser() {
      // console.log('Form submitted')
      // console.log(this.addForm)
      this.errors.addUserErrors.fullnameErrors = this.addForm.fullname
        ? []
        : [this.$t("user_view.user_name_not_present")];
      this.errors.addUserErrors.emailErrors = this.addForm.email
        ? []
        : [this.$t("user_view.email_not_present")];
      this.errors.addUserErrors.emailErrors =
        this.addForm.email.toLowerCase() === "admin"
          ? [this.$t("user_view.email_wrong")]
          : [];
      this.errors.addUserErrors.organizationIdErrors = this.addForm
        .organizationId
        ? []
        : [this.$t("user_view.org_not_present")];
      if (!this.addFormReady) {
        // console.log('form not valid')
        return;
      }
      if (this.addForm.password === "") {
        this.addForm.password = null;
      }
      // console.log(this.addForm)
      let cleanForm = JSON.parse(JSON.stringify(this.addForm));

      // transform accessList
      cleanForm.accessList = [];
      cleanForm.accessListNames = [...new Set(cleanForm.accessListNames)];
      for (let i = 0, len = cleanForm.accessListNames.length; i < len; i++) {
        cleanForm.accessList.push(
          ...this.accesses.filter(
            (a) => a.name === cleanForm.accessListNames[i]
          )
        );
      }
      delete cleanForm.accessListNames;
      // filer access to have unique values
      cleanForm.accessList = [...new Set(cleanForm.accessList)];
      //filer apps to have unique values
      cleanForm.appsAccessList = [...new Set(cleanForm.appsAccessList)];
      // console.log(JSON.stringify(cleanForm))
      this.backend.addUser(cleanForm, this.$i18n.locale).then((d2) => {
        if (d2 !== false) {
          this.addForm.fullname = "";
          this.addForm.email = "";
          this.addForm.organizationId = "";
          this.addForm.password = "";
          this.addForm.appsAccessList = [this.allAppsAccess];
          this.addForm.accessListNames = [];
          this.addForm.sendEmail = true;
          this.addForm.enabled = true;
          this.getUsers();
          this.showAddUser = false;
        } else {
          this.launchToast(this.$t("user_view.error_create"), "warning");
        }
      });
    },

    cancelAdd() {
      // console.log('Clearing data on cancel dialog')
      this.addForm.fullname = "";
      this.addForm.email = "";
      this.addForm.organizationId = "";
      this.addForm.password = "";
      this.addForm.appsAccessList = [this.allAppsAccess];
      this.addForm.accessListNames = [];
      this.addForm.sendEmail = true;
      this.addForm.enabled = true;
      this.errors.addUserErrors.fullnameErrors = [];
      this.errors.addUserErrors.emailErrors = [];
      this.errors.addUserErrors.organizationIdErrors = [];
      this.showAddUser = false;
    },

    getUsers() {
      this.backend.listUser().then((data) => {
        // console.log(data)
        if (data !== false) {
          this.tableData = data;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].appsAccessListNames = [];
            for (let j = 0; j < this.tableData[i].appsAccessList.length; j++) {
              this.tableData[i].appsAccessListNames.push(
                this.tableData[i].appsAccessList[j].name
              );
            }
            this.tableData[i].accessListNames = [];
            for (
              let k = 0, len = this.tableData[i].accessList.length;
              k < len;
              k++
            ) {
              this.tableData[i].accessListNames.push(
                this.tableData[i].accessList[k].name
              );
            }
            this.tableData[i].accessListNames = [
              ...new Set(this.tableData[i].accessListNames),
            ];
            this.tableData[i].enabled2FA = {
              enabled: this.tableData[i].enabled2FA,
              id: this.tableData[i].id,
            };
          }
        } else {
          this.tableData = [];
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },

    getOrganizations() {
      this.backend.listOrganization().then((data) => {
        // console.log(data)
        if (data !== false) {
          this.organizations = data.map((a) => a.orgId);
        } else {
          this.organizations = [];
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },

    getApps() {
      this.backend.listApp().then((data) => {
        // console.log(data)
        if (data !== false) {
          this.apps = this.apps.concat(data);
        } else {
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },

    getAccs() {
      this.backend.listAcc().then((data) => {
        // console.log(data)
        if (data !== false) {
          this.accesses = data;
          this.accessNames = [];
          for (let i = 0, len = this.accesses.length; i < len; i++) {
            this.accessNames.push(this.accesses[i].name);
          }
          // console.log(JSON.stringify(this.accessNames))
          this.accessNames = [...new Set(this.accessNames)];
          // console.log(JSON.stringify(this.accessNames))
        } else {
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },

    launchToast(text, col) {
      this.$vaToast.init({
        message: text,
        position: "top-left",
        duration: 4000,
        fullWidth: false,
        color: col,
      });
    },

    removeUser(userId) {
      // console.log('Called remove')
      const user = this.tableData.find(({ id }) => id === userId);
      // set only if in admin app
      if (user.adminListed) {
        this.editedUser.id = user.id;
      }
      this.editedUser.email = user.email;
      this.showConfirmDelete = true;
    },

    cancelDelete() {
      this.editedUser.id = "";
      this.editedUser.email = "";
      this.showConfirmDelete = false;
    },

    confirmDelete() {
      // check if in admin app
      if (this.editedUser.id === "") {
        this.backend.removeUserGtw(this.editedUser.email, {}).then((d2) => {
          if (d2 !== false) {
            this.editedUser.email = "";
            this.getUsers();
            this.showConfirmDelete = false;
          } else {
            this.launchToast(this.$t("user_view.error_delete"), "warning");
          }
        });
      } else {
        this.backend.removeUser(this.editedUser.id, {}).then((d2) => {
          if (d2 !== false) {
            this.editedUser.id = "";
            this.editedUser.email = "";
            this.getUsers();
            this.showConfirmDelete = false;
          } else {
            this.launchToast(this.$t("user_view.error_delete"), "warning");
          }
        });
      }
    },

    editUserModal(userId) {
      // console.log('Called edit')
      const user = this.tableData.find(({ id }) => id === userId);
      this.editedUser.id = user.id;
      this.editedUser.fullname = user.fullname;
      this.editedUser.enabled = user.enabled;
      this.editedUser.email = user.email;
      this.editedUser.organizationId = user.organizationId;
      this.editedUser.appsAccessList = user.appsAccessList;
      this.editedUser.accessListNames = user.accessListNames;
      this.showEditUser = true;
    },

    cancelEdit() {
      this.editedUser.id = "";
      this.editedUser.fullname = "";
      this.editedUser.enabled = "";
      this.editedUser.email = "";
      this.addForm.organizationId = "";
      this.editedUser.password = "";
      this.editedUser.appsAccessList = [];
      this.editedUser.accessListNames = [];
      this.errors.editUserErrors.fullnameErrors = [];
      this.editedUser.enabled = true;
      this.showEditUser = false;
    },

    editUser() {
      this.errors.editUserErrors.fullnameErrors = this.editedUser.fullname
        ? []
        : [this.$t("user_view.user_name_not_present")];
      this.errors.editUserErrors.organizationIdErrors = this.editedUser
        .organizationId
        ? []
        : [this.$t("user_view.org_not_present")];
      if (
        this.errors.editUserErrors.fullnameErrors.length ||
        this.errors.editUserErrors.organizationIdErrors.length
      ) {
        // console.log('form not valid')
        return;
      }
      if (this.editedUser.password === "") {
        this.editedUser.password = null;
      }
      // console.log(this.addForm)
      let cleanForm = JSON.parse(JSON.stringify(this.editedUser));

      // transform accessList
      cleanForm.accessList = [];
      cleanForm.accessListNames = [...new Set(cleanForm.accessListNames)];
      for (let i = 0, len = cleanForm.accessListNames.length; i < len; i++) {
        cleanForm.accessList.push(
          ...this.accesses.filter(
            (a) => a.name === cleanForm.accessListNames[i]
          )
        );
      }
      delete cleanForm.accessListNames;
      // filer access to have unique values
      cleanForm.accessList = [...new Set(cleanForm.accessList)];
      //filer apps to have unique values
      cleanForm.appsAccessList = [...new Set(cleanForm.appsAccessList)];
      // console.log(JSON.stringify(cleanForm))
      this.backend.editUser(this.editedUser.id, cleanForm).then((d2) => {
        if (d2 !== false) {
          this.editedUser.id = "";
          this.editedUser.fullname = "";
          this.editedUser.enabled = "";
          this.editedUser.email = "";
          this.addForm.organizationId = "";
          this.editedUser.password = "";
          this.editedUser.appsAccessList = [];
          this.editedUser.enabled = true;
          this.getUsers();
          this.showEditUser = false;
        } else {
          this.launchToast(this.$t("user_view.error_edit"), "warning");
        }
      });
    },

    disable2FAModal(userId) {
      const user = this.tableData.find(({ id }) => id === userId);
      this.editedUser.id = user.id;
      this.editedUser.email = user.email;
      this.showDisable2FA = true;
    },
    cancelDisable2FA() {
      this.editedUser.id = "";
      this.editedUser.email = "";
      this.showDisable2FA = false;
    },
    disable2FA() {
      this.backend.disable2FA(this.editedUser.id).then((d2) => {
        if (d2 !== false) {
          this.editedUser.id = "";
          this.editedUser.email = "";
          this.getUsers();
          this.showDisable2FA = false;
        } else {
          this.launchToast(this.$t("user_view.error_edit"), "warning");
        }
      });
    },
  },
};
</script>

<style lang="scss">
.formRow {
  min-width: 20vw;
}
</style>
